import moment from 'moment-timezone';
import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { calendar, shield, user } from '../../../../utilities/images';
import products from "../../../db/category.json"
import CustomModal from '../../../components/shared/CustomModal';
import AddEmployeeModal from './addEmployeeModal';
const SystemEmployee = () => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const history = useHistory()
    const columns1 = [
      {
        name: '#',
        selector: row => row.id,
        width: "50px" 
      },
      {
        name: "Name",
        selector: row => {
        return (
              <>
                <div className='d-flex align-items-center'>
                  <img className='userInnerImg' src={user} />
                  <div className='ms-2'>
                    <span className='textParagh '>Teri J Henderson</span>
                  </div>
                </div>
              </>
            );
        },
        width: "200px", 
      },
      { name: 'Role',
      selector: row => {
        return (
              <>
                <span className='textParagh '>Sales Manager</span>
              </>
            );
        },
        width: "220px",
      },
      {
        selector: row => {
          return (
                <>
                  <span className='textParagh '>97976397</span>
                </>
              );
          },
          width: "150px",
        name: 'Employee ID'
      },
      {
        name: 'Date',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>17 Jun, 2020</span>
                </>
              );
          },
          width: "130px",
      },
      
      {
        selector: row => {
          return (
                <>
                  <span className='textParagh '>38</span>
                </>
              );
          },
        name: 'Age'
      },
      {
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Female</span>
                </>
              );
          },
        name: 'Gender'
      },
      {
        name: 'Status',
        selector: row => {
          return (
                <>
                  <div className='ms-2 tableBtn__'>
                      <Link to='' className='innerBtn_'>Active</Link>
                      {/* <i class="fal fa-chevron-right ms-3" ></i>  */}
                  </div>
                </>
              );
          },
          width: "160px"
      },
      {
        name: 'Action',
        selector: row => {
          return (
                <>
                <div className='d-flex align-items-center'>
                  <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                      <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                  </div>
                </div>
                </>
              );
          },
          width: "120px",
      },
      {
        name: '',
        selector: row => {
          return (
                <>
                <i class="fal fa-chevron-right " ></i> 
                </>
              );
          },
          width: "50px",
      },
    ];
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='instantWithdrawal'>
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>Wallet Employees</span> </h2>
              </div>
              <div class="col-md-6 text-end">
                <div className='customDropdown'>
                  <h2 className="backgroundFillbtn" to="" id="exportDropdown" data-bs-toggle="dropdown" aria-expanded="false"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "AddNewEmplyoee" });
                    setKey(Math.random());
                  }}
                  >Add New Employee</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters'>
                <div className="calenderIcon">
                  <img src={calendar} alt="calender" />
                  <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                </div>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Country
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Id type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
          </div>
        </div>
        <DataTable
          pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "AddNewEmplyoee" ? "commonWidth" : ""}
          ids={modalDetail.flag === "AddNewEmplyoee" ? "addNewEmplyoee" : ""}
          // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
          child={
            modalDetail.flag === "AddNewEmplyoee" ? (
              <AddEmployeeModal close={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          header={
            <div className='modalHeader_'>
              <div className="common_">
                  <h2 className="modalHeading">
                      Add New Employee Details
                  </h2>
                  <button class="closeButton">
                    <i class="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
    </div>
  )
}

export default SystemEmployee