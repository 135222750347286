import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/forgetPassword" component={Containers.forgetPassword} />
      <Layouts.NonPrivateRoute exact path="/resetPassword" component={Containers.resetPassword} />
      <Layouts.NonPrivateRoute exact path="/walletLogin" component={Containers.walletLogin} />
      <Layouts.NonPrivateRoute exact path="/twoAuthenticationModal" component={Containers.TwoAuthentication} />
      {/* <Layouts.PrivateRoute exact path="/wallet" component={Containers.Dashboard} /> */}
      <Layouts.PrivateRoute exact path="/wallet" component={Containers.Wallet} />
      <Layouts.PrivateRoute exact path="/overview" component={Containers.Overview} />
      <Layouts.PrivateRoute exact path="/verification/newApplication" component={Containers.NewApplication }/>
      <Layouts.PrivateRoute exact path="/fiatoverview" component={Containers.FiatOverview} />
      <Layouts.PrivateRoute exact path="/verification/applicationDetailkyc" component={Containers.ApplicationDetailkyc} />
      <Layouts.PrivateRoute exact path="/verification/detailsKyb" component={Containers.DetailsKyb} />
      <Layouts.PrivateRoute exact path="/newApplication" component={Containers.NewApplication} />
      <Layouts.PrivateRoute exact path="/verification/verified" component={Containers.Verified} />
      <Layouts.PrivateRoute exact path="/verification/verifiedDetails" component={Containers.VerifiedDetails} />
      <Layouts.PrivateRoute exact path="/verification/verifieddetailKyb" component={Containers.VerifieddetailKyb} />
      <Layouts.PrivateRoute exact path="/verification/deniedApplication" component={Containers.DeniedApplication} />
      <Layouts.PrivateRoute exact path="/verification/deniedDetails" component={Containers.DeniedDetails} />
      <Layouts.PrivateRoute exact path="/verification/denieddetailKyb" component={Containers.DenieddetailKyb} />

      {/* Start FiatToTocken */}
      <Layouts.PrivateRoute exact path="/fiatTocken/overview" component={Containers.FiatOverview} />
      <Layouts.PrivateRoute exact path="/fiatTocken/tokenTransections" component={Containers.TokenTransection} />
      <Layouts.PrivateRoute exact path="/fiatTocken/referralTokenTransections" component={Containers.ReferralTokenTransections} />
      {/* End FiatToTocken */}
      {/* Start TokenFiat */}
      <Layouts.PrivateRoute exact path="/tokenFiat/label" component={Containers.TokenOverview} />
      <Layouts.PrivateRoute exact path="/tokenFiat/instantWithdrawal" component={Containers.InstantWithdrawal} />
      <Layouts.PrivateRoute exact path="/tokenFiat/sameWithdrawal" component={Containers.SameWithdrawal} />
      <Layouts.PrivateRoute exact path="/tokenFiat/standardWithdrawal" component={Containers.StandardWithdrawal} />
      <Layouts.PrivateRoute exact path="/tokenFiat/standardWithdrawal" component={Containers.StandardWithdrawal} />
      {/* End TokenFiat */}
      {/* Start TokenUsage */}
      <Layouts.PrivateRoute exact path="/tokenUsage/overview" component={Containers.TokenUsageoverview} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenAdded" component={Containers.TokenAdded} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenPayment" component={Containers.TokenPayment} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenRefunded" component={Containers.TokenRefunded} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenRequested" component={Containers.TokenRequested} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenSent" component={Containers.TokenSent} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenpaymentInvoice" component={Containers.TokenpaymentInvoice} />
      <Layouts.PrivateRoute exact path="/tokenUsage/tokenrefundInvoice" component={Containers.TokenrefundInvoice} />

      {/* End TokenUsage */}
      {/* Start UserList */}
      <Layouts.PrivateRoute exact path="/userList/overview" component={Containers.UserOverview} />
      <Layouts.PrivateRoute exact path="/userList/activeUsers" component={Containers.ActiveUsers} />
      <Layouts.PrivateRoute exact path="/userList/inactiveUsers" component={Containers.InactiveUsers} />
      <Layouts.PrivateRoute exact path="/userList/disableUsers" component={Containers.DisableUsers} />
      <Layouts.PrivateRoute exact path="/userList/activemarchant" component={Containers.Activemarchant} />
      <Layouts.PrivateRoute exact path="/userList/activeCustomer" component={Containers.ActiveCustomer} />
      <Layouts.PrivateRoute exact path="/userList/inactiveMarchant" component={Containers.InactiveMarchant} />
      <Layouts.PrivateRoute exact path="/userList/inactiveCustomer" component={Containers.InactiveCustomer} />
      <Layouts.PrivateRoute exact path="/userList/disableMarchant" component={Containers.DisableMarchant} />
      <Layouts.PrivateRoute exact path="/userList/disableCustomer" component={Containers.DisableCustomer} />

      {/* End UserList */}
      {/* Start Support */}
      <Layouts.PrivateRoute exact path="/support/supportOverview" component={Containers.SupportOverview} />
      <Layouts.PrivateRoute exact path="/support/suportTicket" component={Containers.SuportTicket} />
      <Layouts.PrivateRoute exact path="/support/supportCalls" component={Containers.SupportCalls} />
      <Layouts.PrivateRoute exact path="/support/supportChats" component={Containers.SupportChats} />
      <Layouts.PrivateRoute exact path="/support/supportEmail" component={Containers.SupportEmail} />
      {/* End Support */}
      {/* Start System Config */}
      <Layouts.PrivateRoute exact path="/systemConfig/systemEmployee" component={Containers.SystemEmployee} />
      <Layouts.PrivateRoute exact path="/systemConfig/userRoles" component={Containers.UserRoles} />
      <Layouts.PrivateRoute exact path="/systemConfig/bankandcardList" component={Containers.BankandcardList} />
      <Layouts.PrivateRoute exact path="/systemConfig/thirdpartyApi" component={Containers.ThirdpartyApi} />
      <Layouts.PrivateRoute exact path="/systemConfig/systemSetting" component={Containers.SystemSetting} />
      <Layouts.PrivateRoute exact path="/systemConfig/termsandCondition" component={Containers.TermsandCondition} />
      <Layouts.PrivateRoute exact path="/systemConfig/systemFaq" component={Containers.SystemFaq} />
      {/* End System Config */}
      {/* Start Disbursement */}
      <Layouts.PrivateRoute exact path="/disbursement/disbursementOverview" component={Containers.DisbursementOverview} />
      <Layouts.PrivateRoute exact path="/disbursement/disburseOnhold" component={Containers.DisburseOnhold} />
      <Layouts.PrivateRoute exact path="/disbursement/disbursementPaid" component={Containers.DisbursementPaid} />
       {/* End Disbursement */}
      {/* Start Fees Settings */}
      <Layouts.PrivateRoute exact path="/feesSettings/feesIndex" component={Containers.FeesIndex} />
      {/* End Fees Settings */}
      {/* Start Purchases History */}
      <Layouts.PrivateRoute exact path="/purchaseHistory/purchaseIndex" component={Containers.PurchaseIndex} />
      {/* End Purchases History */}
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;