import React from 'react'
import { Link } from 'react-router-dom';
import { downlight, shareMap } from '../../../../utilities/images';

const Wallet = () => {
    document.title = "Wallet";
  return (
    <div className='wallet'>
      <div className='row mb-3'>
        <div className='col-md-12 text-end'>
              <div class="dropdown weekDropdown">
                  <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      This week <img src={downlight} alt="img" />
                  </button>
                  <ul class="dropdown-menu weekDropdownMenus">
                      <li><a class="dropdown-item">This week</a></li>
                      <li><a class="dropdown-item">Last Week</a></li>
                      <li><a class="dropdown-item">This Month</a></li>
                      <li><a class="dropdown-item">This Quarter </a></li>
                      <li><a class="dropdown-item">This Year</a></li>
                  </ul>
              </div>
        </div>
      </div>
      {/* Start PercentageContainer Section */}
      <div className='verificationTotal'>
        <div className='row'>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                  <div className='percentageValues'>
                      <h3 className='heading_ '>10,029</h3>
                      <p className='textInner'>Total Applied</p>
                  </div>
                  <div className='percentageRating'>
                      <img src={shareMap} className="" alt='ShareMap' />
                      <p className='percentageRate'>1.2%</p>
                  </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                  <div className='percentageValues'>
                      <h3 className='heading_ '>9,960</h3>
                      <p className='textInner'>Total Verified</p>
                  </div>
                  <div className='percentageRating'>
                      <img src={shareMap} className="" alt='ShareMap' />
                      <p className='percentageRate'>1.2%</p>
                  </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                  <div className='percentageValues'>
                      <h3 className='heading_ '>163</h3>
                      <p className='textInner'>New Applications</p>
                  </div>
                  <div className='percentageRating'>
                      <img src={shareMap} className="" alt='ShareMap' />
                      <p className='percentageRate'>1.2%</p>
                  </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 '>
                <div className='percentageContainer'>                          
                  <div className='percentageValues'>
                      <h3 className='heading_ '>3</h3>
                      <p className='textInner'>Application denied</p>
                  </div>
                  <div className='percentageRating'>
                      <img src={shareMap} className="" alt='ShareMap' />
                      <p className='percentageRate'>1.2%</p>
                  </div>
                </div>
            </div>
      </div>
      </div> 
      {/* End PercentageContainer Section */}
      <div className='salesTrend'>
        <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 mt-3 mb-3'>
                <div className='chartsOuter'>
                    <h3 className='textInnerHead'>Sales Trends</h3>
                    <div className='graphContainer'>
                      <img src='https://www.pngall.com/wp-content/uploads/12/Graph-PNG-Images-HD.png' className='graphImg' alt='Graph'/>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-6'>
                <div className='chartsOuter'>
                    <h3 className='textInnerHead'>Token Trades analysis</h3>
                    <div className='graphContainer'>
                      <img src='https://www.pngall.com/wp-content/uploads/12/Graph-PNG-Images-HD.png' className='graphImg' alt='Graph'/>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-6'>
                <div className='chartsOuter'>
                    <h3 className='textInnerHead'>Fees earning analysis</h3>
                    <div className='graphContainer'>
                      <img src='https://www.pngall.com/wp-content/uploads/12/Graph-PNG-Images-HD.png' className='graphImg' alt='Graph'/>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
  )
}

export default Wallet