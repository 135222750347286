import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../app/redux/actions/auth'
import { toast } from 'react-toastify';
import { brandLogo, disbursement, feesSetting, fiatTocken, fiatTockenActive, home, homeActive, purchase, setting, support, tockenUsage, userList, userListActive, verification, verificationActive } from '../../../utilities/images';
const Sidebar = (props) => {
    const [activeData, setActiveData] = useState("")
    const location = useLocation();
    const pathName = location.pathname;
    const history = useHistory();
    const userLogout = () => {
        props.logout();
        toast.success("Logout Successfully");
        history.push('/')
}
    return (
        <aside className="main-sidebar" id="myNav">
            <Link to="/dashboard" className="brandLogo">
                <img src={brandLogo} className="img-fluid" alt="" />
            </Link>
            <Link to="#" className="closebtn" ><i className="las la-times"></i></Link>
            <ul className="sidebarMenus navbar_overlay_content_">
                <div className='sidebarStaticMenus'>
                    <li className="sidebarItems dropdown">
                        <Link className="sidebarLinks dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true" id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Dashboard</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName.includes('wallet') ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/wallet"
                                className={pathName == '/wallet' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Wallet
                                </Link>
                            </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Verification Hub</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                                <li className='sidebarInnerItems'>
                                    <Link 
                                    to="/overview"
                                    className={pathName === '/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                        </svg>
                                        Overview
                                    </Link>
                                </li>
                                <li className='sidebarInnerItems'>
                                    <Link 
                                    to="/newApplication"
                                    className={pathName === '/newApplication' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                        </svg>
                                        New Applications
                                    </Link>
                                </li>
                                <li className='sidebarInnerItems'>
                                    <Link 
                                    to="/verification/verified"
                                    className={pathName === '/verification/verified' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                        </svg>
                                        Verified Applications
                                    </Link>
                                </li>
                                <li className='sidebarInnerItems'>
                                    <Link 
                                    to="/verification/deniedApplication"
                                    className={pathName === '/verification/deniedApplication' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                        </svg>
                                        Denied Applications
                                    </Link>
                                </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'tokenUsage' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("tokenUsage")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={tockenUsage} className="img-fluid showImg" alt="" />
                            {/* <img src={shippingOrderDark} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>JBR Token Usage</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/tokenUsage/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/overview"
                                className={pathName == '/tokenUsage/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/tokenAdded"
                                className={pathName == '/tokenUsage/tokenAdded' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Added
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/tokenSent"
                                className={pathName == '/tokenUsage/tokenSent' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Sent
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/tokenPayment"
                                className={pathName == 'tokenUsage/tokenPayment' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Payment
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/tokenRequested"
                                className={pathName == '/tokenUsage/tokenRequested' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Requested
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenUsage/tokenRefunded"
                                className={pathName == '/tokenUsage/tokenRefunded' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Refunded
                                </Link>
                            </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'fiatTocken/overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("fiatTocken/overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Fiat to Token</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/fiatTocken/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/fiatTocken/overview"
                                className={pathName == '/fiatTocken/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/fiatTocken/tokenTransections"
                                className={pathName == '/fiatTocken/tokenTransections' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Token Transections
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/fiatTocken/referralTokenTransections"
                                className={pathName == '/fiatTocken/referralTokenTransections' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Referral Token Transections
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/fiatoverview"
                                className={pathName == '/fiatoverview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    All Transections
                                </Link>
                            </li>
                            
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'tokenFiat/label' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("tokenFiat/label")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={fiatTocken} className="img-fluid showImg" alt="" />
                            {/* <img src={fiatTockenActive} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>JBR Token Fiat</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/tokenFiat/label' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/label"
                                className={pathName == '/tokenFiat/label' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Label
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/instantWithdrawal"
                                className={pathName == '/tokenFiat/instantWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Instant Withdrawal
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/sameWithdrawal"
                                className={pathName == '/tokenFiat/sameWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Same day Withdrawal
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/tokenFiat/standardWithdrawal"
                                className={pathName == '/tokenFiat/standardWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Standard Withdrawal
                                </Link>
                            </li>
                            
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'userList/overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link  className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("userList/overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={userList} className="img-fluid showImg" alt="" />
                            {/* <img src={userListActive} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>User List</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === '/userList/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/overview"
                                className={pathName == '/userList/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/activeUsers"
                                className={pathName == '/userList/activeUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Active Users
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/inactiveUsers"
                                className={pathName == '/userList/inactiveUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Inactive Users
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link 
                                to="/userList/disableUsers"
                                className={pathName == '/userList/disableUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Disable Users
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeData == 'tray' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/drawerCash" className="sidebarLinks" onClick={() => setActiveData("tray")}>
                            <img src={purchase} className="img-fluid showImg" alt="" />
                            {/* <img src={trayDark} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>Purchases History</span>
                        </Link>
                    </li>
                    <li className={activeData == 'disbursement/disbursementOverview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("disbursement/disbursementOverview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={disbursement} className="img-fluid" alt="" />
                            <span className='menusTxt'>Disbursement</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === 'disbursement/disbursementOverview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementOverview"
                                className={pathName == '/disbursement/disbursementOverview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disburseOnhold"
                                className={pathName == '/disbursement/disburseOnhold' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    On Hold
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementPaid"
                                className={pathName == '/disbursement/disbursementPaid' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Paid
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeData == 'reward' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/rewards" className="sidebarLinks" onClick={() => setActiveData("reward")}>
                            <img src={feesSetting} className="img-fluid" alt="" />
                            <span className='menusTxt'>Fees Settings</span>
                        </Link>
                    </li>
                    <li className={activeData == 'systemConfig/systemEmployee' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link  className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("systemConfig/systemEmployee")}
                            data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={setting} className="img-fluid" alt="" />
                            <span className='menusTxt'>System Config</span>
                            <i class="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === 'systemConfig/systemEmployee' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                        aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/systemEmployee"
                                className={pathName == '/systemConfig/systemEmployee' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Employee
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/userRoles"
                                className={pathName == '/systemConfig/userRoles' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    User Roles
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/bankandcardList"
                                className={pathName == '/systemConfig/bankandcardList' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    List of Banks & Cards
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/bankandcardList"
                                className={pathName == '/systemConfig/bankandcardList' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    3rd party API
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementPaid"
                                className={pathName == '/disbursement/disbursementPaid' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    System Settings
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementPaid"
                                className={pathName == '/disbursement/disbursementPaid' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    Terms and Condition
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementPaid"
                                className={pathName == '/disbursement/disbursementPaid' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot'/>
                                    </svg>
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebarItems" onClick={() => {userLogout()}}>
                        <Link to="#" className="sidebarLinks" onClick={() => setActiveData("power")}>
                            <img src={support} className="img-fluid" alt="" />
                            <span className='menusTxt'>Support</span>
                        </Link>
                    </li>
                </div>
                <div className='sidbarfixedMenus'>
                    <li className="sidebarItems" onClick={() => {userLogout()}}>
                        <Link to="" className="sidebarLinks" onClick={() => setActiveData("power")}>
                            <img src={logout} className="img-fluid" alt="" />
                            <span className='menusTxt'>Logout</span>
                        </Link>
                    </li>
                </div>
            </ul>
            {/* <ul className='sidebarMenus navbar_overlay_content'>
                
            </ul> */}
        </aside>
    )
}
const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);