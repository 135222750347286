import React from 'react'
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import products from "../../db/category.json"
const UserRoles = () => {
    const history = useHistory()
    const columns1 = [
      {
        name: '#',
        selector: row => row.id,
        width: "50px" 
      }, 
      { name: 'Role name',
      selector: row => {
        return (
              <>
                <span className='textParagh '>Admin</span>
              </>
            );
        },
        width: "580px",
      },
      {
        selector: row => {
          return (
                <>
                  <span className='textParagh '>3</span>
                </>
              );
          },
          width: "150px",
        name: 'Users'
      },
      {
        name: '',
        selector: row => {
          return (
                <>
                  <div className='dropdown'>
                    <button className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                      <i class="fal fa-ellipsis-v icon_"></i>
                    </button>
                    <div className='dropdown-menu' aria-labelledby='barMenus'>
                      <li className='dropdown-item'>Hello</li>
                    </div>
                  </div>
                </>
              );
          },
          width: "50px",
      },
    ];
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='instantWithdrawal'>
        
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>User Role</span> </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <Link to="#" className='valuesTimeswallet active'>Roles</Link>
            <Link to="#" className='valuesTimeswallet'>Assigned Users</Link>
          </div>
        </div>
        <DataTable
          pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
    </div>
  )
}

export default UserRoles