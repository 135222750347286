import React from 'react'
import { Link } from 'react-router-dom'
import { bell, chat, search_light, Search_light, userImg } from '../../../utilities/images'

function DashboardNavbar() {
  return (
    <nav className='mainHeader'>
      <div className='row align-items-center'>
        <div className='col-md-4'>
          <div class="input-group search_group">
            <img src={search_light} alt="" class="img-fluid searchIcon" />
              <input type="text" class="form-control" placeholder="Search here" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          </div>
        </div>
        <div className='col-md-8 text-end'>
            <ul className='rightNav'>
              <li className='rightNavItems'>
                <Link to='#'>
                  <img src={chat}  className='icons_' alt='ChatImg'/>
                </Link>
              </li>
              <li className='rightNavItems'>
                <Link to='#'>
                  <img src={bell}  className='icons_' alt='BellImg'/>
                </Link>
              </li>
              <li className='rightNavItems'>
                  <div className='dropdown adminDropdown' >
                    <Link id='dropdownAdminButton' data-bs-toggle='dropdown' aria-expanded='false'>
                      <span>System Admin</span>
                      <span className='userProfile'>
                        <img src={userImg}  className='userImg me-2' alt='UserImage'/>
                        <i class="fal fa-chevron-down"></i>
                      </span>
                    </Link>
                    <ul className='dropdown-menu dropdownAdminMenus' aria-labelledby="dropdownAdminButton">
                      <li className='dropdown-item'>
                        <Link to='' className='dropdown-link'>Profile</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to='' className='dropdown-link'>Edit</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to='' className='dropdown-link'>Setting</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to='/' className='dropdown-link'>Logout</Link>
                      </li>
                    </ul>
                  </div>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  )
}

export default DashboardNavbar