import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { resetrightimg } from "../../../utilities/images";

const ResetPassword = () => {
  const history = useHistory();

  return (
    <>
      <div className="commonLogin resetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Create a New Password</h4>
                  <p className="smallText">Your new password must be different from previous used passwords.</p>
                </div>
                <form className="form_horizontal">
                <div className="mb-1 form-group">
                    <label class="form-label">New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name="password"
                      type={"password"}
                    />
                    <p className="textLine">Must be at least 8 characters</p>
                    <span class="toggle_password_ info_icon"><span class="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="mb-1 form-group">
                    <label class="form-label">Confirm New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name="password"
                      type={"password"}
                    />
                    <span class="toggle_password_ info_icon"><span class="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn mt-4" type="text">
                      <span>Set New Password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={resetrightimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
