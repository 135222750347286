import React,{useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { forgetpassimg } from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';
import ScheduleModal from './ScheduleModal';

const ForgetPassword = () => {
  const [key, setKey] = useState(Math.random());

  const history = useHistory();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  return (
    <>
      <div className="commonLogin forgetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Have you forgot password?</h4>
                  <p className="smallText">To reset your password, enter your email address</p>
                </div>
                <form className="form_horizontal">
                  <div className="mb-4 form-group">
                    <label class="form-label">Email address/User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Email address/User Name"
                    />
                  </div>
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn mt-1" type="button" onClick={() => {
                      setModalDetail({ show: true, flag: 'ScheduleModal'});
                      setKey(Math.random());
                    }}>
                      <span>Send link</span>
                    </button>
                    <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i class="fa-solid fa-arrow-left-long"></i> Go back to <Link to="#" onClick={() => {history.push("/") }}> Login </Link> </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={forgetpassimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ScheduleModal" ? "scheduleModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={modalDetail.flag === 'ScheduleModal' ? <ScheduleModal close={() => handleOnCloseModal()} />
          : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ForgetPassword;
