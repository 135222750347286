import React, {useState}  from 'react'
import { resetmodalimg } from "../../../utilities/images";
// import {
//     customerprofilee,
//     customerphone,
//     customeremail,
//     Pin_light,
//   } from "../../../utilities/images";

const ScheduleModal = (props) => {
    const [activeClass, setActivewidth] = useState(false);
  const openActions = (id) => {
    document.getElementById(id).setAttribute("style", "width:32%");
    document.getElementById("scrollHide").setAttribute("style", "overflow:hidden")
    // document.getElementById("paymentDetails").setAttribute("style", "overflow:hidden")
    setActivewidth(true)
  }
    const closeActions = (id) => {
        document.getElementById(id).setAttribute("style", "width:0");
        setActivewidth(false)
      }
  return (
    <div className='noHeaderModal'>
        
             <button class="closeAction"><i class="las la-times" onClick={props.close}></i></button>
        
        <div className='noHeaderModalcontent'>
          <img src={resetmodalimg} />
          <h4>A link Has sent to your email</h4>
          <p>Please login back to your email inbox and set your new password</p>
       
        </div>
    </div>
  )
}

export default ScheduleModal