import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { loginrightimg } from "../../../utilities/images";

const Login = () => {
  const history = useHistory();

  return (
    <>
      <div className="commonLogin">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Login</h4>
                  <p className="text">Hey! Enter your login details.</p>
                </div>
                <form className="form_horizontal">
                  <div className="mb-4 form-group">
                    <label class="form-label">User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="mb-1 form-group">
                    <label class="form-label">Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password"
                      name="password"
                      type={"password"}
                    />
                    <span class="toggle_password_ info_icon"><span class="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="remeberForgot form-group mb-5">
                    <p className="forgot mb-0" onClick={() => {history.push("/forgetPassword") }}>Forgot Password?</p>
                  </div>
                  {/* <div className="googleAurhentication">
                    <h5>Google  Authentication Code</h5>
                    <ul class="authboxContent">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                  </div> */}
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn" type="submit">
                      <span>Login</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={loginrightimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
