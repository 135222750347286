import React from 'react'
import DataTable from 'react-data-table-component'
import { Link, useHistory } from 'react-router-dom'
import { graph2, shareMap, shield, user, user1 } from '../../../utilities/images'
import products from "../../db/category.json"

const TokenUsageoverview = () => {
  const history = useHistory()
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px" 
    },
    {
      name: "Name",
      selector: row => {
      return (
            <>
              <div className='d-flex align-items-center'>
                <img className='userInnerImg' src={user} />
                <div className='ms-2'>
                  <span className='contentSubtext_ '>{row.name}</span>
                  <p className='mb-0'>
                    <img src={shield} className='img-fluid me-2'/>
                    <span className='textInner'>260 101 480 0083 </span>
                  </p>
                </div>
              </div>
            </>
          );
      },
      width: "300px", 
    },
    { name: 'Date',
    selector: row => {
      return (
            <>
              <div className='ms-2'>
                  <span className='contentSubtext_'>{row.date}</span>
                  <p className='textInner mt-2'>9:00:23a</p>
              </div>
            </>
          );
      },
      width: "220px",
    },
    {
      selector: row => row.value,
      name: 'Transection id'
    },
    {
      selector: row => row.amount,
      name: 'Fiat Amount'
    },
    {
      selector: row => row.amount2,
      name: 'Token Amount'
    },
    {
      selector: row => row.types,
      name: 'Type'
    },
    {
      selector: row => row.fees,
      name: 'Fees'
    },
    {
      name: 'Status',
      selector: row => {
        return (
              <>
                <div className='ms-2 tableBtn__'>
                    <Link to='' className='innerBtn_'>Completed</Link>
                    <i class="fal fa-chevron-right ms-3"></i>
                </div>
              </>
            );
        },
        width: "170px"
    }
  ];

  const handleRowClick = (e) => {
      // console.log("rowclicked", e)
      history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
  }
  return (
    <div className='tokendUsage'>
        <div className='chartsOuter'>
            <div className='row align-items-center'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                    <h3 class="textInnerHead mt-3">Token Trades Value</h3>
                    <h2 className='mainBoldHeading'>$593,056</h2>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-7'>
                  {/* Start PercentageContainer Section */}
                  <div className='verificationTotal mt-4'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$1.25M</h3>
                                  <p className='textInner'>Total Trade Value </p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>3,000</h3>
                                  <p className='textInner'>Number of Trades </p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$300,000</h3>
                                  <p className='textInner'>Average value</p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>25</h3>
                                  <p className='textInner'>Active Customers</p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                  </div>
                  </div> 
                  {/* End PercentageContainer Section */}
                </div>
            </div>
        </div>
        <section className='tokenPaymentSec mt-4'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='chartsOuter'>
                  <h3 className='subheading_'>Recent Payments</h3>
                  <div className='customDetails mt-3'>
                      <div className='customInnerDetails'>
                          <div class="customCount">
                              <img src={user1} className='img-fluid userCustomImg' alt='User'/>
                          </div>
                          <div className='customCountent'>
                              <h4 className='textInnerDark'>$300.50</h4>
                              <h5 className='textInnerHead2'>George J. Maldonado</h5>
                          </div>
                      </div>
                      <div className='customInnerDetails'>
                          <div class="customCount">
                              <img src={user1} className='img-fluid userCustomImg' alt='User'/>
                          </div>
                          <div className='customCountent'>
                              <h4 className='textInnerDark'>$300.50</h4>
                              <h5 className='textInnerHead2'>George J. Maldonado</h5>
                          </div>
                      </div>
                      <div className='customInnerDetails'>
                          <div class="customCount">
                              <img src={user1} className='img-fluid userCustomImg' alt='User'/>
                          </div>
                          <div className='customCountent'>
                              <h4 className='textInnerDark'>$300.50</h4>
                              <h5 className='textInnerHead2'>George J. Maldonado</h5>
                          </div>
                      </div>
                      <div className='customInnerDetails'>
                          <div class="customCount">
                              <img src={user1} className='img-fluid userCustomImg' alt='User'/>
                          </div>
                          <div className='customCountent'>
                              <h4 className='textInnerDark'>$300.50</h4>
                              <h5 className='textInnerHead2'>George J. Maldonado</h5>
                          </div>
                      </div>
                      <div className='customInnerDetails'>
                          <div class="customCount">
                              <img src={user1} className='img-fluid userCustomImg' alt='User'/>
                          </div>
                          <div className='customCountent'>
                              <h4 className='textInnerDark'>$300.50</h4>
                              <h5 className='textInnerHead2'>George J. Maldonado</h5>
                          </div>
                      </div>
                  </div>
                  <Link to='#' className='viewAll mt-2'>View All</Link>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='chartsOuter h-100'>
                <h3 class="textInnerHead mt-3 mb-4">Token Trades Value</h3>
                <img src={graph2} alt='GraphImg' className='img-fluid'/>
              </div>
            </div>
          </div>
        </section>
        <section className='recentTransections mt-4'>
          <h3 className='textInnerHead mb-4'>Recent Token Transections</h3>
          <DataTable
            pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
          />
        </section>
    </div>
  )
}

export default TokenUsageoverview